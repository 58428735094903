<template lang="pug">
.om-tactic-recommendation-card.om-tactic-ai-recommendation-card(
  :class="{ 'om-wizard-brandkit': true }"
  data-track-property="item:recommend-ai"
)
  .om-tactic-recommendation-card-preview
    .om-tactic-recommendation-card-preview-overlay(
      @click="onRequest"
      data-track-property="change:tactic.request-early-access"
    )
      div {{ $t('ai.requestAccess') }}
    .fix-tactic-preview
      img(:src="require('@/assets/admin/img/wizard/ai_early_access.jpg')")
  .om-tactic-recommendation-card-content
    .om-tactic-recommendation-card-content-name.cursor-pointer(@click="onRequest") {{ $t('onboarding.wizard.pages.recommendation.ai.title') }}
    .om-tactic-recommendation-card-content-targeting {{ $t('onboarding.wizard.pages.recommendation.ai.description') }}
    .om-tactic-recommendation-card-content-controls
      .om-tactic-recommendation-card-content-controls-buttons
        om-button.mr-3(
          secondary
          large
          @click="learnMore('wizard-recommendation-ai-learnMore')"
          data-track-property="change:tactic.learn-ai"
        ) {{ $t('learnMore') }}
        om-button(
          v-if="!requestedAI"
          primary
          :loading="isAiEarlyAccessLoading"
          large
          @click="onRequest()"
          data-track-property="change:tactic.request-early-access"
        ) {{ $t('ai.requestAccess') }}
        .ai-request-access-confirmed.text-center.d-flex.align-items-center.justify-content-center(
          v-else
        )
          UilCheckCircle.mr-1(size="1.5em")
          | {{ $t('ai.requestConfirmed') }}
</template>

<script>
  import { track } from '@/services/xray';
  import aiFeaturesMixin from '@/mixins/aiFeatures';
  import { UilCheckCircle } from '@iconscout/vue-unicons';

  export default {
    components: { UilCheckCircle },
    mixins: [aiFeaturesMixin],
    data: () => ({ requestedAI: false }),
    methods: {
      learnMore(trackId) {
        track(trackId, { name: 'AI recommendation - Personalized popups' });
        this.$router.push({ name: 'ai_features' });
      },
      async onRequest() {
        const type = 'tactics_personalizedPopups';
        const key = `aiRequest_${type}`;
        track(`aiRequest_${type}`);
        await this.saveAiEarlyAccessRequest(type);
        this.preSetProfileKey(key);
        this.requestedAI = true;
      },
    },
  };
</script>

<style lang="sass">
  @import "@/sass/variables/_colors.sass"
  .om-tactic-ai-recommendation-card
    background: white
    .fix-tactic-preview
      img
        object-fit: cover
        width: 100%
    .om-tactic-recommendation-card-content
      margin: unset
      padding: 2.75rem 2rem 1.5rem 2.5rem
    .om-tactic-recommendation-card-content-targeting
      flex-grow: 1
    .om-tactic-recommendation-card-content-controls
      justify-content: flex-end
    .ai-request-access-confirmed
      padding: .75rem 1rem
      border-radius: 4px
      font-size: 1rem
      line-height: 1.75
      background: $om-alert-green-50
      color: $om-alert-green-600
</style>
